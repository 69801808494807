@use '../variables' as v
    
.role
    display: flex

    &-info
        flex: 80%

    &-title
        margin: 0.5rem 0
        color: v.$gray

    &-duties
        margin: 0.5rem 0
        padding-inline-start: 2rem

    &-date
        flex: 20%
        font-weight: 600
        padding-top: 0.5rem
        text-align: right
