// Basic colors
$gray-dark: #333
$gray: #555
$gray-pdf: #525659

// Theme colors
$blue-light: #a4c2f4
$blue-dark: #1c4587
$green-light: #d9ead3
$green-dark: #274e13
$red-light: #f4cccc
$red-dark: #660000