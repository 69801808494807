@use '../variables' as v

.certification
    display: flex

    &-title
        flex: 60%
        color: v.$gray

    &-icon
        flex: 25%
        text-align: center
        a > img
            width: 80px
            height: 80px

    &-date
        flex: 15%
        padding-top: 1rem
        font-weight: 600
