@use '../variables' as v

.section-title
    text-align: center
    display: flex
    &.blue
        background-color: v.$blue-light
    &.green
        background-color: v.$green-light
    &.red
        background-color: v.$red-light
    h2
        flex: 1
        margin: 1rem 0 0.5rem 0