.experience
    margin: 0.5rem 0

    &-body
        display: flex
        padding: 0 1rem

    &-title
        padding: 0 1rem
        margin: 0.5rem 0
        a
            margin-left: 0.5rem
            color: black

    &-colorBar
        width: 1rem

    &-color
        width: 100%
        height: 100%

    &-roles
        flex: 1
        padding: 0 0.5rem
