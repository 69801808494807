@use '../variables' as v

.app
    padding: 0.5rem 0
    &-page
        width: 8.5in
        margin: 0 auto
        background-color: white
        box-shadow: 0px 0px 10px v.$gray-dark
    &.nineties
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace
        background: url('../../assets/stars.gif') repeat
        cursor: url('../../assets/cur45.ani'), url('../../assets/cur45.png'), default !important
