@use 'variables' as v

body
	margin: 0
	font-family: Arial, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale
	background-color: v.$gray-pdf
	font-size: 11pt
	font-weight: 500
	code
		font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace
