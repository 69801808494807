.footer
    width: 8.5in
    margin: 0 auto
    padding: 2rem 0
    text-align: center
    &-buttons
        text-align: right
        button
            background-color: white
            margin: 0 0.5rem
            border: none
            border-radius: 1rem
            padding: 0.575rem
            max-width: 2rem
            height: 2rem
            transition: 100ms ease-in-out
            overflow: hidden
            white-space: nowrap
            svg
                margin-right: 0.575rem
            &:hover
                max-width: 10rem
                transition: 100ms ease-in-out
    a
        text-decoration: none
        font-size: 16pt